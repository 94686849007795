import React, { useState } from 'react';
import { Box, Button, Input, Select, VStack, ChakraProvider, Flex, Text, IconButton } from '@chakra-ui/react';
import { ChevronRightIcon, DeleteIcon, AddIcon } from "@chakra-ui/icons";
import { toast } from 'react-toastify';
import axios from 'axios'; 
import Card from '../components/card/Card'
import 'react-toastify/dist/ReactToastify.css';
// import Card from 'components/card/Card.js';

const deviceOptions = {
  Device1: "BMC",
  Device2: "Sanitizer1",
  Device3: "Sanitizer2"
  // Add more options as needed
};

const TreeNode = ({ node, depth, addLevel, isVisible, onNameChange, onDeviceChange }) => {
  const handleNameChange = (e) => {
    onNameChange(node, e.target.value);
  };

  const handleDeviceChange = (value) => {
    onDeviceChange(node, value);
  };

  const handleAddLevel = () => {
    addLevel(node);
  };

  return (
    <VStack align="flex-start" pl={4 * depth}>
    <Box>
      <Box mt={4}>
        <Flex alignItems="center">
          <ChevronRightIcon/>
          {/* <Input placeholder={"Enter the "+node.name+" name"} borderRadius="16px" value={node.name} onChange={handleNameChange} /> */}
              <Input placeholder={"Enter the "+node.name+" name"} borderRadius="16px"  onChange={handleNameChange} />
        </Flex>
      </Box>
      {/* <Box mt={4}>
        <Select style={{ marginLeft: "1rem",width:"95%" }} placeholder="Select device" borderRadius="16px" value={node.device} onChange={(e) => handleDeviceChange(e.target.value)}>
          {Object.entries(deviceOptions).map(([value, label]) => (
            <option key={value} value={value}>{label}</option>
          ))}
        </Select>
      </Box> */}
   </Box>
      <Box>
        <Button   color='white'
              fontSize='md'
              fontWeight='700'
              borderRadius='20px'
              px='24px'
              alignSelf="center"
              py='5px' style={{backgroundColor: "#190793",  marginLeft:"1rem",marginTop:"1rem" }} onClick={handleAddLevel}>
          {/* Add within {node.name} <AddIcon style={{ color: "white" }} ml={4} /> */}
         Add level within {node.name}  <AddIcon style={{ color: "white" }} ml={4} fontWeight="700"/> 
        </Button>
      </Box>
      {isVisible && node.children.map((child, index) => (
        <TreeNode key={index} node={child} depth={depth + 1} addLevel={addLevel} isVisible={true} onNameChange={onNameChange} onDeviceChange={onDeviceChange} />
      ))}
    </VStack>
  );
};

const AccordionComponent = ({ firstWord, instanceName ,solutionName,solutionDescription,jsonSendToParent}) => {
  const [rootNodes, setRootNodes] = useState([{ name: firstWord, device: '', children: [] }]);

// const [buttonVisible1,setButtonVisible1]=useState(false);
  const addRootNode = () => {
    const newRootNode = { name: firstWord, device: '', children: [] };
    setRootNodes([...rootNodes, newRootNode]);
    // setButtonVisible1(true);
  };

  const addLevel = (parentNode) => {
    const newNode = { name: 'New Level', device: '', children: [] };
    parentNode.children.push(newNode);
    setRootNodes([...rootNodes]);
  };

  const deleteRootNode = (index) => {
  
    const confirmDelete = window.confirm("Are you sure you want to delete");
  if (confirmDelete) {
    const updatedRootNodes = rootNodes.filter((_, i) => i !== index);
    setRootNodes(updatedRootNodes);
  } else {
    // User clicked Cancel, stay on the same page
  
 
  }
  };

  const handleNameChange = (node, newName) => {
    node.name = newName;
    setRootNodes([...rootNodes]);
  };

  const handleDeviceChange = (node, newDevice) => {
    node.device = newDevice;
    setRootNodes([...rootNodes]);
  };

  const printNestedObject = () => {
    const nestedObject = convertToNestedObject(rootNodes);
    const completeObject = {
        solutionName: solutionName,
        solutionDescription: solutionDescription,
        levels: {}
    };

    // Add each level object to the completeObject
    nestedObject.forEach((level, index) => {
        completeObject.levels[`level${index + 1}`] = level;
    });

    const jsonString = JSON.stringify(completeObject, null, 2);
    jsonSendToParent(jsonString);
    // console.log(jsonString);
};

const printNestedObject1 = () => {
  const nestedObject = convertToNestedObject(rootNodes);
  const completeObject = {
    solutionName: solutionName,
    solutionDescription: solutionDescription,
    levels: {}
  };

  // Add each level object to the completeObject
  nestedObject.forEach((level, index) => {
    completeObject.levels[`level${index + 1}`] = level;
  });

  const jsonString = JSON.stringify(completeObject, null, 2);
console.log(jsonString);
  
  axios.post('http://192.168.31.75:5010/create_solution',  jsonString )
    .then(response => {
      console.log('Data sent successfully',response);
      if(response.status===200){
     
       const message = response.data.message;
        toast.success(message, { theme: "colored" });
      
      }
      // Handle success response from the backend
    })
    .catch(error => {
      console.error('Error sending data:', error);
     if(error.response.status===400){
   
         const message = error.response.data.message;
        toast.error(message, { theme: "colored" });
     }
    //  else if(error.reponse.status===500)
    // //  setStatus("error");
    //   setResponse1("Internal server error");
      // Handle error response from the backend
    });
};



const convertToNestedObject = (nodes, depth = 1) => {
    return nodes.map(node => ({
        name: node.name,
        device: deviceOptions[node.device] || '', // Map device option value to display name
        [`sublevel${depth}`]: convertToNestedObject(node.children, depth + 1) // Recursively convert children nodes with incremented depth
    }));
};
  return (
    <ChakraProvider>
      <VStack spacing={8} align="flex-start">
        <div style={{display:"flex"}}>
        <Button style={{ backgroundColor: "#190793", color: "white",fontWeight:"700" }}  color='white'
              fontSize='md'
              fontWeight='500'
              borderRadius='10px'
              px='24px'
              py='5px'   onClick={addRootNode} mt={4}>Create another {firstWord}</Button>
     
        
        <Button color='white'
              fontSize='md'
            
              borderRadius='10px'
              px='24px'
              fontWeight="700"
              py='5px'style={{ backgroundColor: "#190793", color: "white" }}     onClick={printNestedObject} mt={4} ml={4}>Preview Json</Button>
     


        </div>
      
        {rootNodes.map((rootNode, index) => (
          <Card key={index} borderWidth="1px" borderRadius="lg"       overflow="auto"
      noScroll
     p="4" w="90%" h="25vh" mt={4}>
            <Flex p="4" align="center" justify="space-between" style={{ backgroundColor: "white" }}>
              {/* <Text  fontSize={{ sm: "10px", lg: "15px" }}
                    color="black">{firstWord.toUpperCase()}</Text> */}
                       <Text  fontSize={{ sm: "10px", lg: "15px" }} fontWeight="700"
                    color="black">{solutionName}</Text>
              <IconButton icon={<DeleteIcon />} variant="unstyled" onClick={() => deleteRootNode(index)} />
            </Flex>
            <TreeNode fontWeight="700" node={rootNode} depth={0} addLevel={addLevel} isVisible={true} onNameChange={handleNameChange} onDeviceChange={handleDeviceChange} />
          </Card>

        ))}
          <Button style={{ backgroundColor: "#190793", color: "white",marginTop:"5rem" }}   color='white'
              fontSize='md'
              fontWeight='700'
              borderRadius='10px'
              px='24px'
              py='5px'  onClick={printNestedObject1} ml={4} >Final Submit</Button>
      </VStack>
    </ChakraProvider>
  );
};

export default AccordionComponent;
