import "./assets/css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom"; // Use 'Router' as an alias for BrowserRouter

import AdminLayout from "./layouts/admin";
import AdminMain from "layouts/adminmain/adminmain";
import AuthLayout from "./layouts/auth";
import { ChakraProvider } from "@chakra-ui/react";
import KeycloakProvider from "auth/KeycloakProvider";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";

import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import theme from "./theme/theme";
import "./disableBrowserButton"

function App() {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 37 || event.keyCode === 39) {
        event.preventDefault();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <KeycloakProvider>
          <ThemeEditorProvider>
            <Router> {/* Use Router instead of BrowserRouter */}
              <Switch>
                <Route path="/auth" component={AuthLayout} />
                <Route path="/admin" component={AdminLayout} />
                <Redirect from="/" to="/auth" />
              </Switch>
            </Router>
          </ThemeEditorProvider>
        </KeycloakProvider>
      </React.StrictMode>
    </ChakraProvider>
  );
}
 

ReactDOM.render(<App />, document.getElementById("root"));