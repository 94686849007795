import React, { useEffect, useState } from 'react';
import { Box, Text, Flex, Tabs, TabList, Tab,SimpleGrid } from "@chakra-ui/react";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import axios from 'axios';
import MiniCalendar from 'components/calendar/MiniCalendar';
import Card from 'components/card/Card';
import { getdevicedata } from 'networks';
function Solution() {
  const [chartSeries, setChartSeries] = useState([]);
  const [range, setRange] = useState('1h'); // Default to 1 minute
  const [showCalendar, setShowCalendar] = useState(false); // Controls visibility of MiniCalendar
  const [activeTab, setActiveTab] = useState(null); 
  const [latestParameters, setLatestParameters] = useState({});
  useEffect(() => {
    const fetchData = async (selectedRange) => {
      let endTime = new Date();
      let startTime = new Date();

      switch (selectedRange) {
        case '1h':
          startTime.setHours(endTime.getHours() - 1);
          break;
        case '6h':
          startTime.setHours(endTime.getHours() - 6);
          break;
        case '1d':
          startTime.setDate(endTime.getDay() - 1);
          break;
        case '1w':
            startTime.setDate(endTime.getDay() - 7);
            break;
        case '1M':
              startTime.setDate(endTime.getDay() - 30);
              break;  
        default:
          // Default to 1 minute
          startTime.setMinutes(endTime.getMinutes() - 1);
      }

      try {
        const response = await axios.post(getdevicedata(), {
          mac_id: JSON.parse(sessionStorage.getItem("macid")),
          start: startTime.toISOString(),
          end: endTime.toISOString()
        });

        const apiData = response.data.device_details;
        const seriesData = {
          H: [],
          T: [],
          T1: [],
          T2: []
        };
        const latestDataPoint = response.data.device_details[response.data.device_details.length - 1];
        // Assuming you want to display H, T, T1, T2 as latest parameters
        setLatestParameters({
          H: latestDataPoint.H[1],
          T: latestDataPoint.T[1],
          T1: latestDataPoint.T1[1],
          T2: latestDataPoint.T2[1],
          cmotor: latestDataPoint.cmotor[1],
          lid: latestDataPoint.lid[1],
          rssi: latestDataPoint.rssi[1],
          seq: latestDataPoint.seq[1],
          smotor: latestDataPoint.smotor[1],
          Datetime: new Date(latestDataPoint.smotor[0]).toLocaleString(),
          // Include other parameters as needed
        });
        apiData.forEach(detail => {
          seriesData.H.push([detail.H[0], detail.H[1]]);
          seriesData.T.push([detail.T[0], detail.T[1]]);
          seriesData.T1.push([detail.T1[0], detail.T1[1]]);
          seriesData.T2.push([detail.T2[0], detail.T2[1]]);
        });

        const highchartsSeries = Object.entries(seriesData).map(([name, data]) => ({
          name,
          data
        }));

        setChartSeries(highchartsSeries);
      } catch (error) {
        console.error("Error fetching data from the API:", error);
      }
    };

    fetchData(range);
  }, [range]);

  const handleTabChange = (index) => {
    const ranges = ['1h', '6h', '1d', '1w', '1M'];
    setRange(ranges[index]);
    if (index === 5) { // Assuming the "1 Day" tab shows the calendar
      if (activeTab === index) { // If the same tab is clicked again
        setShowCalendar(!showCalendar); // Toggle visibility
      } else {
        setShowCalendar(true); // Show if a different tab was previously active
      }
    } else {
      setShowCalendar(false); // Hide for all other tabs
    }

    setActiveTab(index);
  };

  const chartOptions = {
    chart: {
      type: 'spline'
    },
    title: {
      text: JSON.parse(sessionStorage.getItem("device_name"))
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: 'Time'
      }
    },
    yAxis: {
      title: {
        text: 'Values'
      }
    },
    series: chartSeries
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card mb="20px">
       <Flex justifyContent="center" mb="20px">
        
          <SimpleGrid columns={3} spacing={4}>
          {Object.entries(latestParameters).map(([key, value]) => (
            <Text key={key}>{`${key}: ${value}`}</Text>
          ))}
          </SimpleGrid>
      </Flex>
      </Card>
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />
      {showCalendar && <MiniCalendar style={{justifyContent:"center",display:"flex"}}/>}
      <Tabs variant='unstyled' onChange={handleTabChange} style={{marginTop:"20px",justifyContent:"center",display:"flex"}}>
        <TabList>
          <Tab _selected={{ color: 'white', bg: 'blue.500' }}>1 Hour</Tab>
          <Tab _selected={{ color: 'white', bg: 'green.400' }}>6 Hours</Tab>
          <Tab _selected={{ color: 'white', bg: 'teal.500' }}>1 Day</Tab>
          <Tab _selected={{ color: 'white', bg: 'blue.500' }}>1 Week</Tab>
          <Tab _selected={{ color: 'white', bg: 'green.400' }}>1 Month</Tab>
          {/* <Tab _selected={{ color: 'white', bg: 'green.400' }}>Calender</Tab> */}
          
        </TabList>
      </Tabs>
    </Box>
  );
}

export default Solution;
