import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import DefaultAuth from "layouts/auth/Default";
import { FcGoogle } from "react-icons/fc";
// Custom components
import { HSeparator } from "components/separator/Separator";
import KeycloakContext from "auth/KeycloakContext";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { RiEyeCloseLine } from "react-icons/ri";
import axios from "axios";
// Assets
import illustration from "assets/img/auth/iot1.png";
import { signIn } from "networks";
import { tenant_list } from "networks";
import { useHistory } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import theme from "theme/theme";

function SignIn({ isSuperAdmin }) {
  // Chakra color mode
  const notify = () => toast("Wow so easy!");
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
  const googleText = useColorModeValue("navy.700", "white");
  const googleHover = useColorModeValue(
    { bg: "gray.200" },
    { bg: "whiteAlpha.300" }
  );
  const googleActive = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.200" }
  );
  const [show, setShow] = useState(false);
  const [tenantList, setTenantList] = useState([]);
  const history = useHistory();
  const { signInToKeycloak } = useContext(KeycloakContext);
  const handleClick = () => setShow(!show);

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    // tenant_id: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSignIn = async () => {
    try {
      const response = await axios.post(signIn(), formData);
      signInToKeycloak(response.data.user_profile);

      // Read status from the response headers
      const status = response.status;

      // Check if response status is in the 2xx range (indicating success)
      if (status >= 200 && status < 300) {
        // Redirect to '/admin/default'
        history.push("/admin/superadmin");
       
        // You can handle the response as needed, e.g., redirect or show a success message

        
        toast.success("Sign in successful", { theme: "colored" })

      } else {
        // Handle other status codes (4xx and 5xx)
        // toast("Sign in successful")
        console.error("Error: Received unexpected HTTP status code:", status);
        // You might want to show an error message to the user
        toast.error(status.response.data.Message, { theme: "colored" });
        // showErrorToast(toast, "Error", "Failed to sign in. Please try again later.", 3000);
      }
    } catch (error) {
      // console.error("Error sending data to backend:", error);
      if (error.response && error.response.data && error.response.data.message) {
        // Display the specific error message from the backend
        toast.error(error.response.data.message, { theme: "colored" });
    } else if (error.response && error.response.status === 400) {
        // Handle 400 Bad Request error with a specific message
        toast.error("Invalid username or password. Please try again.", { theme: "colored" });
    } else {
        // Display a generic error message
        toast.error("An error occurred. Please try again later.", { theme: "colored" });
    }
    }
  };


  useEffect(() => {
    // Fetch tenant list from the API when the component mounts
    fetchTenantList();
  }, []);

  const fetchTenantList = async () => {
    try {
      const response = await axios.get(tenant_list());
      setTenantList(response.data.tenants_list);
    
    } catch (error) {
      console.error("Error fetching tenant list:", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSignIn();
    }
  };


  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <ToastContainer />
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Enter your username and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <Button
            fontSize="sm"
            me="0px"
            mb="26px"
            py="15px"
            h="50px"
            borderRadius="16px"
            bg={googleBg}
            color={googleText}
            fontWeight="500"
            _hover={googleHover}
            _active={googleActive}
            _focus={googleActive}
          >
            <Icon as={FcGoogle} w="20px" h="20px" me="10px" />
            Sign in with Google
          </Button>
          <Flex align="center" mb="25px">
            <HSeparator />
            <Text color="gray.400" mx="14px">
              or
            </Text>
            <HSeparator />
          </Flex>
          <FormControl onKeyPress={handleKeyPress}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
             Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="text"
              placeholder="Enter your username"
              mb="24px"
              fontWeight="500"
              size="lg"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
            />
            {/* {formData.username !== "superadmin" && (<>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Tenant ID<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select
                placeholder="Select Tenant"
                isRequired={true}
                variant="auth"
                fontSize="sm"
                mb="24px"
                fontWeight="500"
                size="lg"
                name="tenant_id"
                ms={{ base: "0px", md: "0px" }}
                value={formData.tenant_id}
                onChange={handleInputChange}
              >
                {tenantList.map((tenant) => (
                  <option value={tenant.tenant_id} key={tenant.tenant_id}>
                    {tenant.tenant_name}
                  </option>
                ))}
              </Select>
            </>
            )} */}

            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>

            <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  id="remember-login"
                  colorScheme="brandScheme"
                  me="10px"
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to="/auth/forgot-password">
                <Text
                  color={textColorBrand}
                  fontSize="sm"
                  w="124px"
                  fontWeight="500"
                >
                  Forgot password?
                </Text>
              </NavLink>
            </Flex>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              onClick={handleSignIn}
            >
              Sign In
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Not registered yet?
              <NavLink to="/auth/sign-up">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Flex>
      </Flex>

    </DefaultAuth>
  );
}

export default SignIn;
