import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table, Thead, Tbody, Tr, Th, Td, TableContainer, IconButton,
  useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalFooter, ModalBody, ModalCloseButton, Button, Select, Input,Box
} from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import { getdevices } from 'networks';
import {  toast } from 'react-toastify';
import { postconfig } from 'networks';
function DevicesTable() {
  const [devices, setDevices] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [formData, setFormData] = useState({
    dispenseQuantity: '',
    dispenseType: '',
    wifiSSID: '',
    wifiPassword: '',
  });
  const [selectedDevice, setSelectedDevice] = useState(null);

  useEffect(() => {
    // Fetch devices
    fetchData()
  }, []);

  const handleOpenModal = (device) => {
    setSelectedDevice(device);
    onOpen();
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(getdevices());
      console.log(response.data)
      setDevices(response.data.data)
      // Assuming the response data is in the format you expect for tableData
      // setDashboard(response.data.solution_types);

    } catch (error) {
      console.error('Error fetching device data:', error);
      if (error.response && error.response.data && error.response.data.message) {
        // If backend returns a specific error message, display it
        toast.error(error.response.data.message,{theme:"colored"});
      } else {
        // Otherwise, display a generic error message
        toast.error('Error fetching device data. Please try again.');
      }
      // Handle error appropriately
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Assuming 'YOUR_API_ENDPOINT' is the endpoint where you want to POST the data
    try {
      await axios.post(postconfig(), {
        ...formData,
        deviceId: selectedDevice.id // or any other device identifier you need to send
      });
      onClose(); // Close the modal on successful submission
      // Optionally reset form state or set success message
    } catch (error) {
      console.error("Failed to submit form:", error);
      // Optionally set error message state here
    }
  };

  return (
    <>
   <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
     <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>Device Name</Th>
              <Th>MAC Address</Th>
              <Th>Serial Number</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {devices.map((device, index) => (
              <Tr key={index}>
                <Td>{device.device_name}</Td>
                <Td>{device.mac_id}</Td>
                <Td>{device.serial_number}</Td>
                <Td>
                  <IconButton
                    aria-label="Settings"
                    icon={<SettingsIcon />}
                    onClick={() => handleOpenModal(device)}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      {selectedDevice && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Device Settings</ModalHeader>
            <ModalCloseButton />
            <ModalBody as="form" onSubmit={handleSubmit}>
              <Select name="dispenseQuantity" placeholder="Select quantity" onChange={handleChange} value={formData.dispenseQuantity} mb={3}>
                <option value="0.5">0.5</option>
                <option value="1">1</option>
                <option value="1.5">1.5</option>
                <option value="2">2</option>
              </Select>
              <Select name="dispenseType" placeholder="Select type" onChange={handleChange} value={formData.dispenseType} mb={3}>
                <option value="soap">Soap</option>
                <option value="gel">Gel</option>
                <option value="alcohol">Alcohol</option>
              </Select>
              <Input name="wifiSSID" placeholder="WiFi SSID" onChange={handleChange} value={formData.wifiSSID} mb={3} />
              <Input name="wifiPassword" placeholder="WiFi Password" onChange={handleChange} value={formData.wifiPassword} mb={3} />
              
            </ModalBody>
            <ModalFooter>
            <Button colorScheme="blue" type="submit">Submit</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        
      )}
      </Box>
    </>
  );
}

export default DevicesTable;
