import React, { useState, useEffect } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

const ChartComponent = () => {
    // State to hold the min and max values
    const [minMax, setMinMax] = useState({ min: null, max: null });

    // State for chart options
    const [chartOptions, setChartOptions] = useState({
        chart: {
            zoomType: 'x', // Enable zooming along the x-axis
        },
        title: {
            text: 'Daily Temperatures for April',
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                month: '%e. %b',
                year: '%b',
            },
            title: {
                text: 'Date',
            },
            events: {}
        },
        yAxis: {
            title: {
                text: 'Temperature (°C)',
            },
        },
        series: [{
            name: 'Temperature',
            data: [] // Initialized with empty data, will be set in useEffect
        }]
    });

    // Function to generate sample data
    const generateSampleData = () => {
        const data = [];
        const startDate = new Date(new Date().getFullYear(), 3, 1).getTime();
        for (let i = 0; i < 30; i++) {
            const date = new Date(startDate + i * 24 * 60 * 60 * 1000).getTime();
            const temp = Math.round(10 + Math.random() * 15);
            data.push([date, temp]);
        }
        return data;
    };

    useEffect(() => {
        // Update the chartOptions state to include the generated data and the afterSetExtremes event handler
        setChartOptions(prevOptions => ({
            ...prevOptions,
            series: [{
                ...prevOptions.series[0],
                data: generateSampleData(),
            }],
            xAxis: {
                ...prevOptions.xAxis,
                events: {
                    afterSetExtremes: function(event) {
                        setMinMax({ min: event.min, max: event.max });
                    }
                }
            }
        }));
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
            <div>
                Min Date: {minMax.min ? Highcharts.dateFormat('%e %b %Y', minMax.min) : 'N/A'}, 
                Max Date: {minMax.max ? Highcharts.dateFormat('%e %b %Y', minMax.max) : 'N/A'}
            </div>
        </div>
    );
};

export default ChartComponent;
