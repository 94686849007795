// const BASE_URL = "http://192.168.31.236:5010";
// const TEST_URL = "http://192.168.31.249:5010";
// const BASE_URL = "http://103.120.62.149:5000";
const BASE_URL = "http://68.183.93.204:5000";
// const BASE_URL = "http://localhost:5000";
// Change backend server IP here
// const KEYCLOAK_URL = "http://192.168.31.236:8085";

export const getBlocks = (data) => {
  return `${BASE_URL}/tree_view?solution_id=${data.solutionname}&path=${data.data}`;
};

export const getFloors = (data) => {
  return `${BASE_URL}/tree_view?solution_id=${data.solutionname}&path=${data.root}`;
};

export const getApartments = (data) => {
  return `${BASE_URL}/tree_view?solution_id=${data.solutionname}&path=${data.root}`;
};

export const getDevices = (data) => {
  return `${BASE_URL}/tree_view?solution_id=${data.solutionname}&path=${data.root}`;
};

// export const devicetelemetry = () => {
//   return `${TD_URL}/rest/sql/iot_platform`;
// };

export const solutiontypes = (data) => {
  return `${BASE_URL}/get_all_solution_types?tenant_id=${data}`;
};

export const tenant_list = () => {
  return `${BASE_URL}/tenant_list`;
};

export const addtenant = (data) => {
  return `${BASE_URL}/tenant_registry`;
};

export const edittenant = (data) => {
  return `${BASE_URL}/edit_tenant?tenant_id=${
    data.editingIndex
  }&&new_tenant_name=${encodeURIComponent(data.newTenant)}`;
};

// export const Keycloak_URL = () => {
//   return `${KEYCLOAK_URL}`;
// };

export const upload_solution_and_device = () => {
  return ` ${BASE_URL}/upload_solution_and_device`;
};

export const download_json = (data) => {
  return ` ${BASE_URL}/download_json/${data}`;
};

export const getTeanantUserList = (data ,username) => {
  return ` ${BASE_URL}/tenant_users?tenant_id=${data}&username=${username}`;
};

export const userResetPassword = () => {
  return `${BASE_URL}/reset_password`;
};

export const editUserProfile = () => {
  return `${BASE_URL}/edit_profile`;
};

export const device_list = (data) => {
  return `${BASE_URL}/device_list?tenant_id=${data}`;
};

export const getUserRoles = (data) => {
  return `${BASE_URL}/user_roles?tenant_id=${data}`;
};

export const addUser = () => {
  return `${BASE_URL}/user_registration`;
};

export const getCountriesUrl = () => {
  return "https://restcountries.com/v3.1/all";
};

export const signIn = () => {
  return `${BASE_URL}/userlogin`;
};
export const logOut = () => {
  return `${BASE_URL}/userlogout`;
};


export const disableTenant = () => {
  return `${BASE_URL}/disable_tenant`;
};

export const disableTenantForUserManagement = () => {
  return `${BASE_URL}/disable_user`;
};

export const deleteTenant = () => {
  return `${BASE_URL}/delete_tenant`;
};
export const createRole = () => {
  return `${BASE_URL}/createrole`;
};
export const deleteRole = () => {
  return `${BASE_URL}/deleterole`;
};
export const devicetelemetry = () => {
  return `${BASE_URL}/telemetry`;
}
export const getalertparameters = (data) => {
  return `${BASE_URL}/device_params?thing_id=${data}`;
}
export const postalertparameters = () => {
  return `${BASE_URL}/create_alert`;
}
export const systemhealth = () => {
  return `${BASE_URL}/health_monitoring`;
}
export const getalerts = () => {
  return `${BASE_URL}/getalerts`;
}
export const getallalerts = () => {
  return `${BASE_URL}/getallalerts`;
}
export const getalertconfigurationlist = () => {
  return `${BASE_URL}/list_alerts`;
}
export const userlogin = () => {
  return `${BASE_URL}/userlogin`;
}
export const getdevices = () => {
  return `${BASE_URL}/getdevices`;
}
export const getdevicedata = () => {
  return `${BASE_URL}/getdevicedata`;
}
export const postconfig = () => {
  return `${BASE_URL}/postconfig`;
}