export const columnsDataDevelopment = [
  {
    Header: "DEVICE",
    accessor: "device_suffix",
  },
  {
    Header: "MAC_ID",
    accessor: "mac_id",
  },
  {
    Header: "SERIAL_NUMBER",
    accessor: "serial_no",
  },
  {
    Header: "SOLUTION",
    accessor: "solution_name",
  },
  // {
  //   Header: "STATUS",
  //   accessor: "status",
  // },
  // {
  //   Header: "DATE",
  //   accessor: "date",
  // },
  // {
  //   Header: "ACTIONS",
  //   accessor: "actions",
  // },
];
