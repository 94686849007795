import React,{useEffect,useState} from 'react';
import { Box, Flex, Text, useColorModeValue, SimpleGrid, Button,Link as ChakraLink, Image} from "@chakra-ui/react";
import { Link } from 'react-router-dom';
import { getdevices } from 'networks';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Card from 'components/card/Card.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBatteryFull,faBatteryEmpty } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Sanitizerimage from 'assets/sanitizer.jpg'
import { BiNoSignal,BiSignal1,BiSignal2,BiSignal3,BiSignal4 } from "react-icons/bi";
import { TiMinus } from "react-icons/ti";
function Solution() {
  const textColor = useColorModeValue("#11047a", "white");
  const textColorSecondary = "black";
  const [devices, setDevices] = useState([]);
  const fetchData = async () => {
    try {
      // Replace with your API call
      const response = await axios.get(getdevices());
      const apiData = response.data.data; // Adjust based on your actual API response structure
      
      const mappedDevices = apiData.map(device => ({
        name: device.device_name,
        macId: device.mac_id,
        serialNo: device.serial_number,
        status: device.status,
        DT:new Date(device.DT*1000).toLocaleString(),
        // parameters: Object.entries(device.sensors).map(([key, value]) => `${key}: ${value}`),
      }));
      
      setDevices(apiData);
    } catch (error) {
      console.error("Error fetching device data:", error);
    }
  };
  const getSignalIcon = (rssi) => {
    const size = 30;
    if (rssi >= -50) {
        return <BiSignal4 size={size} />;
    } else if (rssi >= -70) {
        return <BiSignal3 size={size} />;
    } else if (rssi >= -80) { // Adjusted threshold
        return <BiSignal2 size={size} />;
    } else if (rssi >= -90) {
        return <BiSignal1 size={size} />;
    } else {
        return <BiNoSignal size={size} />;
    }
};
  useEffect(() => {
    

    // Call fetchData every 3 seconds
    const intervalId = setInterval(fetchData, 3000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  useEffect(()=>{
    fetchData()
  },[])
  // Example data for devices with parameters
  

  // Sample data for the line chart

  // Highcharts configuration options for the line chart
 
  const details=(data,data2)=>{
 
    sessionStorage.setItem('macid', JSON.stringify(data));
    sessionStorage.setItem('device_name', JSON.stringify(data2));
  }
   // Assuming device.sensors.level represents the volume level

   const renderVolumeBars = (level) => {
    switch (level) {
      case 'RED':
        return (
          <Box >
            <TiMinus size={30} style={{ marginRight: '-7px',marginBottom: '-23px', color: 'lightgray' }} />
            <TiMinus size={30} style={{ marginRight: '-7px',marginBottom: '-23px', color: 'lightgray' }} />
            <TiMinus size={30} style={{ marginRight: '-7px',marginBottom: '-23px', color: 'lightgray' }} />
            <TiMinus size={30} style={{ marginRight: '-7px', color: 'red' }} />
          </Box>
        );
      case 'AMBER':
        return (
          <Box >
            <TiMinus size={30} style={{  marginRight: '-7px',marginBottom: '-23px', color: 'lightgray' }} />
            <TiMinus size={30} style={{  marginRight: '-7px',marginBottom: '-23px', color: 'lightgray' }} />
            <TiMinus size={30} style={{  marginRight: '-7px',marginBottom: '-23px', color: 'orange' }} />
            <TiMinus size={30} style={{  marginRight: '-7px', color: 'orange' }} />
          </Box>
        );
	  case 'FULL':
        return (
          <Box >
            <TiMinus size={30} style={{ marginRight: '-7px',marginBottom: '-23px', color: 'green' }} />
            <TiMinus size={30} style={{ marginRight: '-7px',marginBottom: '-23px', color: 'green' }} />
            <TiMinus size={30} style={{ marginRight: '-7px',marginBottom: '-23px', color: 'green' }} />
            <TiMinus size={30} style={{ marginRight: '-7px', color: 'green' }} />
          </Box>
        );
      case 'LOW':
        return (
          <Box display="flex">
            <TiMinus size={30} style={{ marginRight: '5px', color: 'gray' }} />
            <TiMinus size={30} style={{ marginRight: '5px', color: 'gray' }} />
            <TiMinus size={30} style={{ marginRight: '5px', color: 'gray' }} />
            <TiMinus size={30} style={{ marginRight: '5px', color: 'green' }} />
          </Box>
        );
      default:
        return null;
    }
  };
  return (
    <Box pt={{ base: '130px', md: '20px', xl: '35px' }} overflowY={{ sm: "scroll", lg: "hidden" }} >
      <Flex flexDirection='column'>
        <Flex mt='45px' mb='20px' justifyContent='space-between' align={{ base: "start", md: "center" }}>
          <Text color={textColor} fontSize='2xl' ms='24px' fontWeight='700'>
            Device Information
          </Text>
        </Flex>
        <SimpleGrid columns={{ base: 1, md: 3 }} gap='20px' mt='20px'>
          {devices.map((device, index) => (
            <Card key={index} p='20px'>
              <Image
            src={Sanitizerimage}
            w={{ base: "100%", "3xl": "100%" }}
            h={{ base: "100%", "3xl": "80%" }}
            borderRadius='20px'
          />


            <Flex direction="column" mt='20px'>
                <Box display="flex" justifyContent="space-between">
                  <Text  mb="15px" color={textColorSecondary}>Device Name:</Text>
                  <Text  mb="15px" color={textColor}>{device.device_name}</Text>
                </Box>
                
                
                <Box display="flex" justifyContent="space-between">
                  <Text  mb="15px" color={textColorSecondary}>Last Dispense:</Text>
                  <Text  mb="15px" color={textColor}>{new Date(device.DT*1000).toLocaleString()} </Text>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <Text  mb="15px" color={textColorSecondary}>Dispense Count:</Text>
                  <Text  mb="15px" color={textColor}>{device.sensors.count} </Text>
                </Box>
                <Box display="flex" justifyContent="space-between">
      <Text  color={textColorSecondary}>Liquid Level:</Text>
      <Text mt="-7px" color={textColor}>
        {renderVolumeBars(device.sensors.level)}
      </Text>
    </Box>
                <Box display="flex" justifyContent="space-between">
                  <Text  color={textColorSecondary}>WiFi Signal:</Text>
                  <Text mt="-10px" marginRight="-10px"color={textColor}>
      {getSignalIcon(device.sensors.rssi)}
    </Text>
                </Box>
              </Flex>
              <Flex justifyContent="flex-end" marginTop={"20px"}>
              <ChakraLink

                    as={Link}
                    to={{ pathname: `/admin/devicedata`}}
                    mt={{
                      base: "0px",
                      md: "10px",
                      lg: "0px",
                      xl: "10px",
                      "2xl": "0px",
                    }}>
              <Button
               variant='darkBrand'
               color='white'
               fontSize='sm'
               fontWeight='500'
               borderRadius='70px'
               px='24px'
               py='5px'
               size="md" 
               ml="auto"
                onClick={()=>details(device.mac_id,device.device_name)}
              >
                Details
              </Button>
              </ChakraLink>
              </Flex>
            </Card>
          ))}
        </SimpleGrid>
        {/* Render the line chart */}
       
      </Flex>
    </Box>
  );
}

export default Solution;
