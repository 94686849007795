import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdDashboard, // New icon for System Health
  MdSupervisorAccount, // New icon for Role Management
  MdPeople,
  MdSchema, // New icon for User Management
  MdAccountBalanceWallet ,
} from "react-icons/md";
import Test from 'test'
// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "marketplace";
import SignInCentered from "views/auth/signIn";

import Solution from "solution/Solution";
import BMC from "solution/bmc";
import EVB from "solution/evb";
import Sanitizor from "solution/sanitizer";
import ProfileSettings from "components/profile/ProfileSettings";
import MainNewSolutionForm from "superadmin/MainNewSolutionForm";
import DeviceManagement from "devicemanagement/devicemanagement";

const routes = [
  

  {
    name: "Dashboard",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/solution",
    component: Solution,
    sidebar:true
  },

  {
    name: "Device Management",
    layout: "/admin",
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: "/devicemanagement",
    component: DeviceManagement,
    sidebar:true
  },

    {
    layout: "/admin",
    // name: "Profile Settings",
    path: "/devices",
    component: ProfileSettings,
    sidebar:false
  },
  {
    layout: "/admin",
    // name: "Profile Settings",
    path: "/devicedata",
    component: Sanitizor,
    sidebar:false
  },
 
  
];

export default routes;

