import React, { useState } from 'react';
import { ChakraProvider, Text, Button, FormControl, FormLabel, Input ,useColorModeValue,Flex } from '@chakra-ui/react';
import { ArrowForwardIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { toast } from 'react-toastify';
import axios from 'axios'; 
import 'react-toastify/dist/ReactToastify.css';
import { Textarea,Radio,RadioGroup,Stack } from '@chakra-ui/react'
import AccordionComponent from './AccordionComponent';
import Card from '../components/card/Card'
function MainNewSolutionForm() {
    const textColorSecondary = "gray.400";
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  // State for managing form data and UI state
  const [open1, setOpen1] = useState(false);
    const [selectedType, setSelectedType] = useState("template");
  const [entireBody, setEntireBody] = useState(true);
  const [firstWord, setFirstWord] = useState('');
  const [solutionName1,setSolutionName1]=useState('');
  const [solutionDescription1,setSolutionDescription1]=useState('');
  const [formData, setFormData] = useState({
    solutionName: '',
    solutionDescription: '',
    instanceName: ''
  });
  const [accordionItems, setAccordionItems] = useState([]);
  const [treeButton,setTreeButton]=useState(false);
  const [currentAccordionLevel, setCurrentAccordionLevel] = useState(-1); // Initialize to -1 (no level selected)

  // Function to handle tree node click event
  const handleNodeClick = (index) => {
    // Update currentAccordionLevel to the clicked index
    setCurrentAccordionLevel(index);
  };
  // Function to handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Function to handle clicking forward arrow button

const printNestedObject1 = () => {
 
console.log(json1);
 const updatedJson1 = {
    ...JSON.parse(json1),
    solutionName: formData.solutionName,
    solutionDescription: formData.solutionDescription,
    
  };
  
  
  axios.post('http://192.168.31.75:5010/create_solution',  updatedJson1 )
    .then(response => {
      console.log('Data sent successfully',response);
      if(response.status===200){
     
       const message = response.data.message;
        toast.success(message, { theme: "colored" });
      
      }
      // Handle success response from the backend
    })
    .catch(error => {
      console.error('Error sending data:', error);
     if(error.response.status===400){
   
         const message = error.response.data.message;
        toast.error(message, { theme: "colored" });
     }
    //  else if(error.reponse.status===500)
    // //  setStatus("error");
    //   setResponse1("Internal server error");
      // Handle error response from the backend
    });
};
  const handleClick = () => {
    setOpen1(true);
    setFirstWord(formData.solutionName.split(' ')[0]);
    setEntireBody(false);
    if (!formData.solutionName || !formData.solutionDescription) {
      setEntireBody(true);
      setOpen1(false);
      return;
    }
  
    // Clear form data
    setFormData({
      solutionName: '',
      solutionDescription: '',
      instanceName: ''
    });
  
    // Clear previously added accordions
    setAccordionItems([]); 
  
    console.log(formData.solutionName);
    setSolutionName1(formData.solutionName);
    setSolutionDescription1(formData.solutionDescription);
  };

  // Function to handle clicking back arrow button
  // const handleBack = () => {
  //   setEntireBody(true);
  //   setOpen1(false);
  //   const confirmBack = window.confirm("Are you sure you want to go back? You will lose all the current data.");
  // };
  // Function to handle clicking back arrow button
const handleBack = () => {
  setEntireBody(true);
  setJson1(`
  {
    "solutionName": "Default solution name",
    "solutionDescription": "Description",
    "levels": [
      "level1":{
        "name": "Default",
        "device": "",
        "sublevel1": []
      }
    ]
  }`);

  // The above is the example for the default json .
  // Click Preview Json button to see the JSON for 
  // the actual solution
  setOpen1(false);
  const confirmBack = window.confirm("Are you sure you want to go back? You will lose all the current data.");
  if (confirmBack) {
    // User clicked OK, proceed with going back
    clearFormData(); // Example function to clear form data
   setTreeButton(false);
  } else {
    // User clicked Cancel, stay on the same page
    setOpen1(true);
    setEntireBody(false);
 
  }
};
const clearFormData = () => {
  setFormData({
    solutionName: '',
    solutionDescription: '',
    instanceName: ''
  });
  setAccordionItems([]);
};
  // Placeholder functions (replace with actual implementations)
  const handleCreateInstance = () => {
    if (formData.instanceName) {
      const newItem = {
        name: formData.instanceName,
        nestedAccordionItems: [] // Initialize nested accordion items
      };
      const updatedAccordionItems = [...accordionItems, newItem];
      setAccordionItems(updatedAccordionItems);
      console.log(updatedAccordionItems);
    }
    setTreeButton(true);
    console.log(formData);
  };

  const handleAddNestedAccordion = (index) => {
    const updatedAccordionItems = [...accordionItems];
    updatedAccordionItems[index].nestedAccordionItems.push({ name: '', selectedDevice: '' }); // Add an empty nested accordion
    setAccordionItems(updatedAccordionItems);
  };

  const handleLevelNameChange = (value, parentIndex, childIndex) => {
    const updatedAccordionItems = [...accordionItems];
    if (updatedAccordionItems[parentIndex]?.nestedAccordionItems?.[childIndex]) {
      updatedAccordionItems[parentIndex].nestedAccordionItems[childIndex].name = value;
      setAccordionItems(updatedAccordionItems);
    }
  };
  const [json1,setJson1]=useState(`
  {
    "solutionName": "Default solution name",
    "solutionDescription": "Description",
    "levels": [
      {
        "name": "Default",
        "device": "",
        "sublevel1": []
      }
    ]
  }
 `);
  // The above is the example for the default json .
  // Click Preview Json button to see the JSON for 
  // the actual solution 
const handleJson=(json)=>{
  setJson1(json);
}
  const handleDeviceChange = (value, parentIndex, childIndex) => {
    const updatedAccordionItems = [...accordionItems];
    if (updatedAccordionItems[parentIndex]?.nestedAccordionItems?.[childIndex]) {
      updatedAccordionItems[parentIndex].nestedAccordionItems[childIndex].selectedDevice = value;
      setAccordionItems(updatedAccordionItems);
    }
  };
  // function SingleSolutionSubmit(){
  //   console.log("Single solution submitted");
  // }

  // JSX rendering
  return (
    <ChakraProvider>
      {/* Header component */}
      {/* <FormHeader/> */}

      {/* Form body */}
      <div style={{margin:"7rem 5rem" }}>
        {entireBody && (
          <>
          <Card>
            <form style={{margin:"0 0rem"}} fontWeight="700">
            {/* Input field for solution name */}
                <FormControl as="fieldset" isRequired mb={20}>
          <FormLabel as="legend" fontSize="md" mb={5} fontWeight="700">Select Solution Type:</FormLabel>
          <RadioGroup defaultValue="template" fontWeight="700" onChange={setSelectedType}>
            <Stack direction="row" spacing={20}>
               <Radio fontSize="md" value="instance" fontWeight="700">Only device based solution</Radio>
              <Radio fontSize="md" value="template" fontWeight="700">Heirarchial based solution</Radio>
             
            </Stack>
          </RadioGroup>
          
        </FormControl>
         {selectedType==="template"&&
        <>
            <FormControl  isRequired mt={10} mb={5}>
              <Flex>
                 <FormLabel width="50%"  color="black" fontSize="md"  fontWeight="700">Enter Solution Template Name</FormLabel>
                {/* <FormLabel>Add Tenant name</FormLabel> */}
              <Input placeholder="Building Management System"    autoFocus={false}   borderRadius="16px" name="solutionName" value={formData.solutionName} onChange={handleChange} />
              </Flex>
             
            </FormControl>

            {/* Input field for solution description */}
            <FormControl  mt={10} mb={10}>
              <Flex> <FormLabel width="50%" style={{margin:"0rem 0rem"}} fontWeight="700">Enter Solution description</FormLabel>
              {/* <Input placeholder="Describe the solution"  autoFocus={false}  borderRadius="16px" name="solutionDescription" value={formData.solutionDescription} onChange={handleChange} /> */}
            <Textarea placeholder='Description' margin="0rem 0rem" height="26vh" name="solutionDescription" value={formData.solutionDescription} onChange={handleChange} /></Flex>
             
            </FormControl>
            

            {/* Forward arrow button */}
            <div style={{textAlign:"end"}}>
               <Button   color='white'
              fontSize='md'
              fontWeight='700'
              borderRadius='15px'
              px='24px'
              py='5px' style={{ backgroundColor: "#190793",  marginTop: "3rem" }} onClick={handleClick}>
              Proceed
              {/* <ArrowForwardIcon ml={4}/> */}
            </Button>
            </div>
          </>
} 
{
  selectedType!=="template"&&
   <>
            <FormControl  isRequired mt={10} mb={5}>
              <Flex>
                 <FormLabel width="50%"  color="black" fontSize="md" fontWeight="700">Enter Solution Template Name</FormLabel>
                {/* <FormLabel>Add Tenant name</FormLabel> */}
              <Input placeholder="Building Management System"    autoFocus={false}   borderRadius="16px" name="solutionName" value={formData.solutionName} onChange={handleChange} />
              </Flex>
             
            </FormControl>

            {/* Input field for solution description */}
            <FormControl  mt={10} mb={5}>
              <Flex> <FormLabel width="50%" style={{margin:"0rem 0rem"}} fontWeight="700">Enter Solution description</FormLabel>
              {/* <Input placeholder="Describe the solution"  autoFocus={false}  borderRadius="16px" name="solutionDescription" value={formData.solutionDescription} onChange={handleChange} /> */}
            <Textarea placeholder='Description' margin="0rem 0rem" height="26vh" name="solutionDescription" value={formData.solutionDescription} onChange={handleChange} /></Flex>
             
            </FormControl>
            

            {/* Forward arrow button */}
            <div style={{textAlign:"end"}}>
               <Button   color='white'
              fontSize='md'
              fontWeight="700"
              borderRadius='15px'
              px='24px'
              py='5px' style={{ backgroundColor: "#190793",  marginTop: "3rem" }} onClick={printNestedObject1}>
              Final Submit
              {/* <ArrowForwardIcon ml={4}/> */}
            </Button>
            </div>
          </>
}
            </form>
            </Card>
          </>
        )}

        {/* Logic for second page */}
        {open1 && (
          <>
            {/* Back arrow button */}
            <div>
              <Button style={{ backgroundColor: "#EDF2F7", color: "#4A5568", marginTop: "1rem", marginBottom:"2rem" }} onClick={handleBack}>
                <ArrowBackIcon />
              </Button>
            </div>

                       <div style={{display:"flex",width:"100%"}}>
       <div style={{width:"50%"}}>

               <AccordionComponent   jsonSendToParent={handleJson} solutionName={solutionName1} solutionDescription={solutionDescription1} instanceName={formData.instanceName} firstWord={firstWord} accordionItems={accordionItems} onAddNestedAccordion={handleAddNestedAccordion} onLevelNameChange={handleLevelNameChange} onDeviceChange={handleDeviceChange} />
               </div>
         <div style={{width:"50%",backgroundColor:"white",color:"black",borderLeft:"1px solid #CBD5E0",marginTop:"5rem"}}>
<h2 style={{textAlign:"center",fontSize:"15px",color:"gray.400",fontWeight:"700",marginBottom:"2rem"}}>Preview JSON</h2>
<Text fontSize="sm"><pre style={{marginLeft:"10rem",width:"100%",fontWeight:"500"}}>{json1}</pre></Text>
         </div>
             </div>
          </>
        )}
      </div>
    </ChakraProvider>
  );
}

// Form header component
function FormHeader() {
  return (
    <Text style={{textAlign: "center", color:"#4318FF",fontSize: "2rem", fontWeight: "1000",marginTop:"2rem"}}>New Solution Form</Text>
  );
}

export default MainNewSolutionForm;
